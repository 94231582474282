import React, { useState, useCallback, useEffect } from 'react'
import PhotoAlbum from 'react-photo-album'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { gcd } from '../utils/utils'
import { useStaticQuery, graphql } from 'gatsby'
import { tagOptions } from '../data/photo_tags'
import './component-style/gallery.scss'

export default function PhotoGallery(props) {
    const [currentImage, setCurrentImage] = useState(0)
    const [viewerOpen, setViewerOpen] = useState(false)
    const [currentTag, setCurrentTag] = useState('all')
    const [photos, setPhotos] = useState([])
    const [activePhotos, setActivePhotos] = useState([])

    const { flickrKeys } = useStaticQuery(graphql`
        query FlickrKeysQuery {
            site {
                siteMetadata {
                    flickrKeys {
                        api_key
                        photoset_id
                    }
                }
            }
        }
    `).site.siteMetadata

    const openLightbox = useCallback((event, photo, index) => {
        setCurrentImage(index)
        setViewerOpen(true)
    }, [])

    const closeLightbox = () => {
        setCurrentImage(0)
        setViewerOpen(false)
    }

    useEffect(() => {
        fetchPictures()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchPictures() {
        fetch(
            `https://api.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=${flickrKeys.api_key}&photoset_id=${flickrKeys.photoset_id}&user_id=149281920%40N03&extras=tags%2Curl_o&format=json&nojsoncallback=1`
        )
            .then(function (response) {
                return response.json()
            })
            .then(data => {
                if (data.photoset) {
                    let oldPhotos = []
                    for (var pic in data.photoset.photo) {
                        let newPic = data.photoset.photo[pic]
                        newPic.src = `https://farm${newPic.farm}.staticflickr.com/${newPic.server}/${newPic.id}_${newPic.secret}_b.jpg`
                        newPic.alt = newPic.title
                        const x = Math.round(newPic.width_o / 10) * 10
                        const y = Math.round(newPic.height_o / 10) * 10
                        const divisor = gcd(x, y)
                        newPic.width = x / divisor
                        newPic.height = y / divisor
                        oldPhotos.push(newPic)
                    }
                    oldPhotos = shuffle(oldPhotos)
                    setPhotos(oldPhotos)
                    setActivePhotos(oldPhotos)
                }
            })
    }

    const shuffle = array => {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex)
            currentIndex -= 1

            // And swap it with the current element.
            temporaryValue = array[currentIndex]
            array[currentIndex] = array[randomIndex]
            array[randomIndex] = temporaryValue
        }

        return array
    }

    const filterImages = tag => {
        setCurrentTag(tag)
        if (tag !== 'all') {
            setActivePhotos(photos.filter(photo => photo.tags.includes(tag)))
        } else {
            setActivePhotos(photos)
        }
    }

    const FilterButton = ({ tag, name }) => (
        <button
            onClick={() => filterImages(tag)}
            className={
                tag === currentTag
                    ? 'filter-button filter-button-selected'
                    : 'filter-button'
            }
        >
            {name}
        </button>
    )

    return (
        <div className="gallery-root">
            <div className="filter-buttons">
                {tagOptions.map((opt, i) => (
                    <FilterButton tag={opt.tag} name={opt.name} key={i} />
                ))}
            </div>
            <PhotoAlbum
                photos={activePhotos}
                layout="rows"
                spacing={5}
                columns={50}
                targetRowHeight={320}
                onClick={openLightbox}
            />
            <ModalGateway>
                {viewerOpen && (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={activePhotos.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                        />
                    </Modal>
                )}
            </ModalGateway>
        </div>
    )
}
