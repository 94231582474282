import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PhotoGallery from '../components/PhotoGallery'

const PhotographyPage = () => (
    <Layout>
        <SEO title="Photography" />
        <h1>Photography Portfolio</h1>
        <PhotoGallery />
    </Layout>
)

export default PhotographyPage
